body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.subjectCOntainer {
  display: flex;
  justify-content: center;
}
.subjectContainerTitle {
  text-align: center;
  font-size: 30px;
  margin: 20px 0;
  color: gray;
}
.subjectContainerFlex {
  margin: auto;
  width: 60%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.subjectContainerItem {
  width: 200px;
  padding: 20px;
  height: 200px;
  background:linear-gradient(to right bottom,#6500B0, #6500B0,#87b8ed);
  font-weight: 500;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
font-size: x-large;
color: white;
}
.subjectContainerFlex a {
  text-decoration: none;
  font-size: 20px;
}
.subjectContainerItem:hover{
  transform: scale(1.2,1.2);
  border-radius: 20px; 
  font-size: xx-large; 
}