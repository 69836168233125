.bodyContainer {
  margin-top: 8rem;
}
.studentImg {
  width: auto;
  height: 320px;
  object-fit: cover;
}
.service__container__img {
  display: flex;
  justify-content: center;
}
.studentImg1 {
  margin: auto;
  width: auto;
  height: 250px;
  object-fit: cover;
}
.bodyContainer__GridItem1 {
  width: 50%;

  margin: auto;
}
.service {
  margin-top: 5rem;
}

.serviceContainer__card {
  padding: 50px 0;
  margin: auto;
  width: 78%;
  display: flex;
  flex-wrap: wrap;
}
