.footer {
  width: 100%;
  background-color: black;
  color: white; 
  text-align: center;
  position: relative;
  bottom: 0px;
  padding: 20px 0;
  margin-top: 10px;
}
